import { apiFetch } from '@api/base';
import { notify } from '@lib/notify';
import type { TAction, TDispatch } from '@models/types';

import { ProfileActionType } from './types';

import type { CustomerDataModel, ProfileAction, ProfileModel } from './types';

const DEFAULT_CUSTOMER_DATA = {
  company_account: '',
  company_address: '',
  company_area: '',
  company_authority_contact: '',
  company_authority_correspondent: '',
  company_authority_name: '',
  company_authority_status: '',
  company_bank: '',
  company_certificate: '',
  company_city: '',
  company_holder: '',
  company_name: '',
  company_NPWP: '',
  company_phone: '',
  company_zip: '',
  email: '',
  group_description: '',
  group_name: '',
  name: ''
};

const ProfileDefault: ProfileModel = {
  main: {
    groupMetaData: DEFAULT_CUSTOMER_DATA,
    groupName: ''
  }
};

const ProfileReducer = (state: ProfileModel = ProfileDefault, action: ProfileAction): ProfileModel => {
  switch (action.type) {
    case ProfileActionType.GetCustomerData:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

const ProfileCommand = {
  getCustomerData: (payload: string, token: string): TAction<ProfileAction, void> => {
    return (dispatch: TDispatch<ProfileAction>) => {
      return apiFetch(token).get(`/registration/1.0/agent/get-user-data?token=${payload}`)
        .then((response) => {
          dispatch({
            data: response.data,
            type: ProfileActionType.GetCustomerData
          });
        });
    };
  },
  putCustomerData: (payload: CustomerDataModel, token: string): Promise<string | undefined> => {
    return apiFetch(token).put(
      '/registration/1.0/agent/update-registration-data',
      payload
    ).then((response) => {
      if (response.status === 200) {
        notify.success('Berhasil memperbaharui profil anda');

        return 'ok';
      }
    });
  }
};

export { ProfileCommand, ProfileReducer, ProfileDefault, DEFAULT_CUSTOMER_DATA };
